import i18n from "@/i18n";
import { SettingsVue } from "fs-pb-vue2/vueModels/settings/SettingsVue";

export class SettingsVueSNB extends SettingsVue {
  
  get actions(): Array<any> {
    const actions: Array<any> = [];
    actions.push({
      /** Смена пароля */
      text: i18n.t("main.settings.changePassword"),
      iconClass: "change-pass-icon",
      action: () => {
        this.openDialog(this.operationsList.passwordEdit);
      }
    });
    actions.push({
      /**Действие оплаты со счета */
      text: i18n.t("main.settings.editAlias"),
      iconClass: "edit-login-icon",
      action: () => {
        this.openDialog(this.operationsList.aliasEdit);
      }
    });
    // actions.push({
    //   /**Действие оплаты со счета */
    //   text: i18n.t("main.settings.logout"),
    //   iconClass: "logout-icon",
    //   action: async () => {
    //     await this.logout();
    //   }
    // });
    return actions;
  }

}
