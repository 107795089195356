import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "fs-pb-vue2/store";
import { AppStore } from "fs-pb-vue2/store/modules/app";
import { getModule } from "vuex-module-decorators";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import "@mdi/font/css/materialdesignicons.css";
import VueMask from 'v-mask';
import filters from "fs-pb-vue2";
import ISessionService from "fs-pb-vue2/services/auth/ISessionService";
import { container } from "./inversify.config";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import VueToast from "vue-toast-notification";
import storeAccessor from "fs-pb-vue2/store/storeAccessor";

/** Импорт тостера уведомлений */
Vue.use(VueToast, {
  position: "top",
  dismissible: true,
  duration: 5000
});

Vue.use(VueMask, {
  placeholders: {      // passing `null` removes default placeholder, so `#` is treated as character
    '9': /[0-9]/,
    '?': /\./, 
    '*': /[0-9A-Za-zА-Яа-я]/,
    'a': /[A-Za-zА-Яа-я]/
  }
})

Vue.config.productionTip = false;

Vue.filter("date", filters.dateFilter);
Vue.filter("sum", filters.sumFilter);
Vue.filter("url", filters.urlFilter);
Vue.filter("phoneNumber", filters.phoneNumberFilter);
Vue.filter("cardNumberFilter", filters.cardNumberFilter);
Vue.filter("currency", filters.currencyFilter);
Vue.filter("cardState", filters.cardState);

router.beforeEach(async (to, from, next) => {
  const appStore: AppStore = getModule(AppStore, store);
  
  appStore.setLoading(true);
  const isAuth = await container
    .get<ISessionService>(DiTokens.SessionService)
    .sessionPing(false, true, true);
  appStore.setLoading(false);

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Для страниц требующих автоиризации проверяем состояние сессии, если сессии нет перебрасываем на окно входа
    if (!isAuth) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresVisitors)) {
    // Для страниц нетребующих автоиризации проверяем состояние сессии, если сессия есть перебрасываем на главную страницу
    if (isAuth) {
      next({
        path: "/"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

storeAccessor.store= store;

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
