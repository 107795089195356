import { MainLayoutVue } from "fs-pb-vue2/vueModels/layouts/MainLayoutVue";

/**Модель представления авторизованной страницы */
export class MainLayoutVueSNB extends MainLayoutVue {

  /**Инициализация стартовых данных приложения */
  async init(): Promise<void> {
    super.init();
    await this.personStore.fetchUserDocumentTypes();
    await this.configStore.fetchCurenccyRates();
    await this.personStore.fetchSubscriptions();
  }

}
