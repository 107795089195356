

































































































































































































import { ContractsPanelVue } from "fs-pb-vue2/vueModels/contracts-panel/ContractsPanelVue";
import { Component } from "vue-property-decorator";
import ContractBlock from "@/components/contracts-panel/ContractBlock.vue";
import DepositBlock from "@/components/contracts-panel/DepositBlock.vue";

@Component({
  components: { ContractBlock, DepositBlock }
})
export default class ContractsPanel extends ContractsPanelVue {}
