






















































































































































































import { MainNavigationVue } from "fs-pb-vue2/vueModels/navigation/MainNavigationVue";
import { Component } from "vue-property-decorator";
import ContractsPanel from "@/components/contracts-panel/ContractsPanel.vue";

@Component({
  components: { ContractsPanel }
})
export default class MainNavigation extends MainNavigationVue {}
