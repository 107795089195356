import { container } from "@/inversify.config";
import { CatalogOperationVue } from "fs-pb-vue2/vueModels/operations/CatalogOperationVue";
import i18n from "@/i18n";


/**Модель представления операции */
export class CatalogOperationVueFS extends CatalogOperationVue {
	/**Метод для выполнения после успешного проведения */
	async afterExecute(): Promise<void> {
	// await this.destroyForm();

	this.appStore.setLoading(true);

	this.successDetails = await this.detailsService.getOperationDetails({ Id: this.OperationModel.OperationId, ReferenceId: "" });
	this.appStore.setLoading(false);
	}
}