





















































import { OperationBlockVue } from "fs-pb-vue2/vueModels/history/OperationBlockVue";
import { Component } from "vue-property-decorator";

@Component({})
export default class OperationBlock extends OperationBlockVue {}
