import "reflect-metadata";
import { Container } from "inversify";
import getDecorators from "inversify-inject-decorators";
import IAuthService from "fs-pb-vue2/services/auth/IAuthService";
import AuthService from "fs-pb-vue2/services/auth/AuthService";
import IConfigService from "fs-pb-vue2/services/config/IConfigService";
import ConfigService from "fs-pb-vue2/services/config/ConfigService";
import ISubscriptionService from "fs-pb-vue2/services/subscription/ISubscriptionService";
import SubscriptionService from "fs-pb-vue2/services/subscription/SubscriptionService";
import IOperationsHistoryService from "fs-pb-vue2/services/history/IOperationsHistoryService";
import OperationsHistoryService from "fs-pb-vue2/services/history/OperationsHistoryService";
import ContractStatementService from "fs-pb-vue2/services/history/ContractStatementService";
import CardStatementService from "fs-pb-vue2/services/history/CardStatementService";
import IPersonService from "fs-pb-vue2/services/person/IPersonService";
import PersonService from "fs-pb-vue2/services/person/PersonService";
import ICatalogService from "fs-pb-vue2/services/catalog/ICatalogService";
import CatalogService from "fs-pb-vue2/services/catalog/CatalogService";
import IContractService from "fs-pb-vue2/services/contracts/IContractService";
import ContractService from "fs-pb-vue2/services/contracts/ContractService";
import CardService from "fs-pb-vue2/services/contracts/CardService";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import AlertService from "fs-pb-vue2/services/alert/AlertService";
import IErrorService from "fs-pb-vue2/services/error/IErrorService";
import ErrorService from "fs-pb-vue2/services/error/ErrorService";
import ISessionService from "fs-pb-vue2/services/auth/ISessionService";
import SessionService from "fs-pb-vue2/services/auth/SessionService";
import IOperationService from "fs-pb-vue2/services/operations/IOperationService";
import CatalogOperationService from "fs-pb-vue2/services/operations/CatalogOperationService";
import ChangePasswordService from "fs-pb-vue2/services/settings/ChangePasswordService";
import SbpEnableService from "fs-pb-vue2/services/settings/SbpEnableService";
import SbpDefaultService from "fs-pb-vue2/services/settings/SbpDefaultService";
import CardBlockService from "fs-pb-vue2/services/cardOperations/CardBlockService";
import CardPinService from "fs-pb-vue2/services/cardOperations/CardPinService";
import ContractSmsService from "fs-pb-vue2/services/contractOperations/ContractSmsService";
import SbpDefaultOutService from "fs-pb-vue2/services/settings/SbpDefaultOutService";
import SbpBankSelectService from "fs-pb-vue2/services/settings/SbpBankSelectService";
import AccountInDefaultService from "fs-pb-vue2/services/settings/AccountInDefaultService";
import RecoveryService from "fs-pb-vue2/services/auth/operations/RecoveryService";
import RegisterService from "fs-pb-vue2/services/auth/operations/RegisterService";
import TemplatesService from "fs-pb-vue2/services/templates/TemplatesService";
import ITemplatesService from "fs-pb-vue2/services/templates/ITemplatesService";
import TemplateEditService from "fs-pb-vue2/services/templates/TemplateEditService";
import TemplateCreateService from "fs-pb-vue2/services/templates/TemplateCreateService";
import ServerCommandsService from "fs-pb-vue2/services/serverCommands/ServerCommandsService";
import SnbRecoveryService from "./extensions/services/auth/operations/SnbRecoveryService";
import SnbRegisterService from "./extensions/services/auth/operations/SnbRegisterService";
import { BaseLoginModel } from "fs-pb-vue2/models/auth/BaseLoginModel";

const container = new Container();

container
  .bind<IAuthService<BaseLoginModel>>(DiTokens.AuthService)
  .to(AuthService)
  .inSingletonScope();

container
  .bind<ISubscriptionService>(DiTokens.SubscriptionService)
  .to(SubscriptionService)
  .inSingletonScope();

container
  .bind<IConfigService>(DiTokens.ConfigService)
  .to(ConfigService)
  .inSingletonScope();

container
  .bind<IContractService>(DiTokens.ContractService)
  .to(ContractService)
  .inSingletonScope();

container
  .bind<IContractService>(DiTokens.CardService)
  .to(CardService)
  .inSingletonScope();

container
  .bind<IPersonService>(DiTokens.PersonService)
  .to(PersonService)
  .inSingletonScope();

container
  .bind<ICatalogService>(DiTokens.CatalogService)
  .to(CatalogService)
  .inSingletonScope();

container
  .bind<IOperationsHistoryService>(DiTokens.OperationsHistoryService)
  .to(OperationsHistoryService)
  .inSingletonScope();

container
  .bind<IOperationsHistoryService>(DiTokens.ContractStatementService)
  .to(ContractStatementService)
  .inSingletonScope();

container
  .bind<IOperationsHistoryService>(DiTokens.CardStatementService)
  .to(CardStatementService)
  .inSingletonScope();

container
  .bind<IAlertService>(DiTokens.AlertService)
  .to(AlertService)
  .inSingletonScope();

container
  .bind<IErrorService>(DiTokens.ErrorService)
  .to(ErrorService)
  .inSingletonScope();

container
  .bind<ISessionService>(DiTokens.SessionService)
  .to(SessionService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.CatalogOperationService)
  .to(CatalogOperationService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.ChangePasswordService)
  .to(ChangePasswordService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.SbpEnableService)
  .to(SbpEnableService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.AccountInDefaultService)
  .to(AccountInDefaultService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.SbpDefaultService)
  .to(SbpDefaultService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.SbpDefaultOutService)
  .to(SbpDefaultOutService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.SbpBankSelectService)
  .to(SbpBankSelectService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.CardBlockService)
  .to(CardBlockService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.CardPinService)
  .to(CardPinService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.ContractSmsService)
  .to(ContractSmsService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.RecoveryService)
  .to(SnbRecoveryService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.RegisterService)
  .to(SnbRegisterService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.TemplateEditService)
  .to(TemplateEditService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.TemplateCreateService)
  .to(TemplateCreateService)
  .inSingletonScope();

container
  .bind<ITemplatesService>(DiTokens.TemplatesService)
  .to(TemplatesService)
  .inSingletonScope();

container
  .bind<IOperationService>(DiTokens.ServerCommandsService)
  .to(ServerCommandsService)
  .inSingletonScope();

//const { lazyInject } = getDecorators(container);
export { container };
