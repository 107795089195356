






























































































































import { ContractBlockVue } from "fs-pb-vue2/vueModels/contracts-panel/ContractBlockVue";
import { Component } from "vue-property-decorator";

@Component({})
export default class ContractBlock extends ContractBlockVue {}
