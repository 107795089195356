




























import { Component } from "vue-property-decorator";
import { AuthCheckVue } from "fs-pb-vue2/vueModels/auth/AuthCheckVue";

@Component({})
export default class AuthCheck extends AuthCheckVue {
  async mounted() {
    await this.init();
  }

  beforeDestroy() {
    clearInterval(this.checkAuthInterval);
  }
}
