


























































import { AuthLayoutVue } from "fs-pb-vue2/vueModels/layouts/AuthLayoutVue";
import { Component } from "vue-property-decorator";
import CommonFooter from "@/components/navigation/CommonFooter.vue";
import AuthNavigation from "@/components/navigation/AuthNavigation.vue";
import AppError from "@/components/app/AppError.vue";

@Component({
  components: { CommonFooter, AuthNavigation, AppError }
})
export default class AuthLayout extends AuthLayoutVue {
  async created() {
    await this.init();
  }
}
