







import Vue from "vue";
import BaseContract from "@/components/contracts/BaseContract.vue";
import Card from "@/components/contracts/Card.vue";

/**Страница ифнормации по счету */
export default Vue.extend({
  components: {
    Card,
    BaseContract
  },
  computed: {
    contractId(): string {
      return this.$route.params.contractId;
    },
    cardId(): string {
      return this.$route.params.cardId;
    },
    isCard(): boolean {
      return !!this.cardId;
    }
  }
});
