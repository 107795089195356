







































































































































































































import { CurrencyRatesVue } from "fs-pb-vue2/vueModels/app/CurrencyRatesVue";
import { Component } from "vue-property-decorator";

@Component()
export default class CurrencyRates extends CurrencyRatesVue {

  get formattedRefreshTime(): string {
    const datePart = this.$options.filters.date(this.Curses.RefreshTime, "rudatafull");
    const timePart = this.$options.filters.date(this.Curses.RefreshTime, "time");
    
    return `${datePart} ${timePart}`;
  }
}
