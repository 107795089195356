














































import { AuthNavigationVueSNB } from "@/extensions/navigation/AuthNavigationVueSNB";
import { Component } from "vue-property-decorator";

@Component()
export default class AuthNavigation extends AuthNavigationVueSNB {}
