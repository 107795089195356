import i18n from "@/i18n";
import { BaseContractVue } from "fs-pb-vue2/vueModels/contracts/BaseContractVue";
import { ContractsTypes } from "fs-pb-vue2/data/enums/contractsTypes";

/** Модель представления счета */
export class BaseContractVueSNB extends BaseContractVue {

  /**Список доступных операций */
  get actions(): Array<any> {
    const actions: Array<any> = [];
    if (this.contract) {
      if (
        this.contract.IsOpen &&
        this.contract.Data.CanDeposit &&
        this.configStore.apiData.OperationCodeOfToContract
      ) {
        /**Действие пополнения счета */
        actions.push({
          text: i18n.t("main.contracts.actions.fill"),
          iconClass: "fill-card-icon",
          action: () => {
            this.$router.push(
              `/operation/0/${this.configStore.apiData.OperationCodeOfToContract}?contractTo=${this.contract.Data.ReferenceId}`
            );
          }
        });
      }
      if (this.contract.IsOpen && this.contract.Data.CanWithdraw) {
        actions.push({
          /**Действие оплаты со счета */
          text: i18n.t("main.contracts.actions.pay"),
          iconClass: "pay-icon",
          action: () => {
            if (this.contract.Data.ContractType === 2 || this.contract.Data.ContractType === 1) 
                this.$router.push(`/operation/0/${this.configStore.apiData.OperationCodeOfToContract}?contract=${this.contract.Data.ReferenceId}`)
            else
                this.$router.push(`/payments?contract=${this.contract.Data.ReferenceId}`);
          }
        });
      }
      if (
        this.configStore.apiData.OperationCodeOfChangeSmsPacket &&
        !this.personInfoSMS &&
        this.contract.Data.ContractType === 4
      ) {
        actions.push({
          text: i18n.t("main.contracts.actions.smsPacket"),
          iconClass: "block-card-icon",
          action: () => {
            this.dialogContent = new Object({
              vueName: "ContractSmsModal",
              params: {
                contractId: this.contract.Data.ReferenceId
              }
            });
            this.dialog = true;
          }
        });
      }
      if (this.contract.Data.ContractType === 2) {
        /** Действие запроса графика для Вкладов */
        actions.push({
          text: i18n.t("main.contracts.actions.forecast", {
            inCome: `${this.contract.Data.FutureIncomeSum} ${this.contract.Currency}`
          }),
          iconClass: "statement-icon",
          action: async () => {
            this.forecastDialog = true;
            if (this.contract.DepositForeCast.length === 0) {
              await this.contractsStore.fetchForeCastDeposit(
                this.contract.Data.Id
              );
            }
          }
        });
      }
      if (this.contract.Data.ContractType === 5) {
        /** Действие запроса графика для кредитов */
        actions.push({
          text: i18n.t("main.contracts.actions.schedule"),
          iconClass: "statement-icon",
          action: async () => {
            this.scheduleDialog = true;
            if (!this.contract.CreditSchedule) {
              await this.contractsStore.fetchScheduleCredit(
                this.contract.Data.Id
              );
            }
          }
        });
      }
      /**Действие запроса выписки по счету*/
      actions.push({
        text: i18n.t("main.contracts.actions.statement"),
        iconClass: "statement-icon",
        action: () => {
          this.$router.push(`/statement/${this.contract.Data.Id}/0`);
        }
      });
      /**Действие запроса реквизитов счета */
      actions.push({
        text: i18n.t("main.contracts.actions.requisits"),
        iconClass: "requisits-icon",
        action: () => {
          this.showRequisites();
        }
      });
      /**Добавить карту */
      if (this.contract.IsOpen && this.contract.Data.ContractType === 4 && this.configStore.apiData.OperationCodeOfCreateVirtualCard) {
        actions.push({
          text: i18n.t("main.contracts.actions.openVirtualCard"),
          iconClass: "fill-card-icon",
          action: () => {
            this.$router.push(
              `/operation/0/${this.configStore.apiData.OperationCodeOfCreateVirtualCard}?contract=${this.contract.Data.ReferenceId}`
            );
          },
        });
      }

      if ( this.contract.IsOpen && this.configStore.apiData.OperationCodeOfCloseDeposit ) {
        /**Действие закрытия счета */
        actions.push({
          text: i18n.t("main.contracts.actions.close"),
          iconClass: "block-card-icon",
          action: () => {
            this.$router.push(
              `/operation/0/${this.configStore.apiData.OperationCodeOfCloseDeposit}?contract=${this.contract.Data.ReferenceId}&operationType=closeAccount`
            );
          }
        });
      }
    }
    return actions;
  }

  /**Список информации */
  get details(): Array<any> {
    let details: Array<any> = [];
    if (this.contract) {
      details = [
        {
          disabled: this.setVisibilityLoading,
          name: i18n.t("main.contracts.details.show"),
          textVal: (isVisible: boolean) => { 
            return isVisible
            ? i18n.t("main.contracts.details.showContract")
            : i18n.t("main.contracts.details.hideContract")
          },
          isAction: true,
          model: !this.contract.Data.Hidden,
          action: async (vis: boolean) => {
            await this.setVisibility();
          }
        }
      ];
      // {
      //   //Тарифный план
      //   name: i18n.t("main.contracts.details.tariff"),
      //   textVal: contract.Data.ContractTypeName,
      // },
      details.push({
        //Тип счёта
        name: i18n.t("main.contracts.details.type"),
        textVal: this.contract.ContractType
      });
      details.push({
        //Номер счета
        name: i18n.t("main.contracts.details.acntNum"),
        textVal: this.contract.Data.MainAccount
      });
      details.push({
        //Состояние
        name: i18n.t("main.contracts.details.state"),
        textVal: this.contract.IsOpen
          ? i18n.t("main.contracts.details.open")
          : i18n.t("main.contracts.details.close")
      });
      details.push({
        //Валюта счета
        name: i18n.t("main.contracts.details.currency"),
        textVal: this.contract.Currency
      });
      details.push({
        //Тип счёта
        name: i18n.t("main.contracts.details.type"),
        textVal: this.contract.ContractType
      });

      details.push({
        //Категория договора
        name: i18n.t("main.contracts.details.contractCategory"),
        textVal: this.contract.Data.ContractTypeName
      });
      if (this.contract.Data.Number) {
        details.push({
          //Номер договора
          name: i18n.t("main.contracts.details.contractNum"),
          textVal: this.contract.Data.Number
        });
      }
      if (this.contract.DateOpen) {
        details.push({
          //Дата открытия
          name: i18n.t("main.contracts.details.dateOpen"),
          textVal: this.contract.DateOpen
        });
      }
      if (this.contract.DateEnd) {
        details.push({
          //Дата завершения
          name: i18n.t("main.contracts.details.dateEnd"),
          textVal: this.contract.DateEnd
        });
      }
      if (!this.contract.IsOpen) {
        //Дата закрытия
        details.push({
          name: i18n.t("main.contracts.details.dateClose"),
          textVal: this.contract.DateClose
        });
      }

      if (this.contract.IsOpen) {
        if (
          this.contract.Data.ContractType !== ContractsTypes.CreditAccount &&
          this.contract.Data.ContractType !== ContractsTypes.TimeAccounts
        ) {
          //Списание
          details.push({
            name: i18n.t("main.contracts.details.canWithdraw"),
            textVal: this.contract.Data.CanWithdraw
              ? i18n.t("main.contracts.details.enable")
              : i18n.t("main.contracts.details.disable")
          });
          //Пополнение
          details.push({
            name: i18n.t("main.contracts.details.canDeposit"),
            textVal: this.contract.Data.CanDeposit
              ? i18n.t("main.contracts.details.enable")
              : i18n.t("main.contracts.details.disable")
          });
        } else if (
          this.contract.Data.ContractType === ContractsTypes.TimeAccounts
        ) {
          //Частичное снятие
          details.push({
            name: i18n.t("main.contracts.details.partCanWithdraw"),
            textVal: this.contract.Data.CanWithdraw
              ? i18n.t("main.contracts.details.enable")
              : i18n.t("main.contracts.details.disable")
          });
          //Ограничения снятия
          details.push({
            name: i18n.t("main.contracts.details.remCanWithdraw"),
            textVal: this.contract.Data.RemCanWithdraw
          });
          //Пополнение
          details.push({
            name: i18n.t("main.contracts.details.canDeposit"),
            textVal: this.contract.Data.CanDeposit
              ? i18n.t("main.contracts.details.enable")
              : i18n.t("main.contracts.details.disable")
          });
          //Ограничения пополнения
          details.push({
            name: i18n.t("main.contracts.details.remCanDeposit"),
            textVal: this.contract.Data.RemCanDeposit
          });
        }

        // Заблокировано на счете
        details.push({
          name: i18n.t("main.contracts.details.lockedSum"),
          textVal: `${this.contract.CalcLockedRetail} ${this.contract.Currency}`
        });
        if (this.contract.Data.ArrestSum > 0) {
          // Арестованная сумма
          details.push({
            name: i18n.t("main.contracts.details.arrestSum"),
            textVal: `${this.contract.ArrestSum} ${this.contract.Currency}`
          });
        }
        if (
          this.contract.Data.MinDepositRest > 0 &&
          this.contract.Data.ContractType == ContractsTypes.DepositAccounts
        ) {
          // Из них неснижаемый остаток
          details.push({
            name: i18n.t("main.contracts.details.minDepositum"),
            textVal: `${this.contract.MinDepositRest} ${this.contract.Currency}`
          });
        }
        //Доступный остаток
        // details.push({
        //   name: i18n.t("main.contracts.details.disposableLimit"),
        //   textVal: `${contract.DisposableLimit} ${contract.Currency}`,
        // });

        if (
          this.contract.Data.ContractType == ContractsTypes.TimeAccounts &&
          this.contract.Data.FutureIncomeSum > 0
        ) {
          //Планируемый доход
          details.push({
            name: i18n.t("main.contracts.details.futureIncomeSum"),
            textVal: `${this.contract.FutureIncomeSum} ${this.contract.Currency}`
          });
        }
      }

      // Выводим остаток для Cрочных счетов и Счетов до востребования
      if (
        this.contract.Data.ContractType == ContractsTypes.DepositAccounts ||
        this.contract.Data.ContractType == ContractsTypes.TimeAccounts
      ) {
        if (!this.contract.IsUseCreditLimit) {
          // Остаток
          details.push({
            name: i18n.t("main.contracts.details.rest"),
            textVal: `${this.contract.Rest} ${this.contract.Currency}`
          });
        }
      }

      // Выводим доступный остаток для Текущих счетов и для Карточных счетов
      if (
        this.contract.Data.ContractType == ContractsTypes.CurrentAccount ||
        this.contract.Data.ContractType == ContractsTypes.CardAccount
      ) {
        // Доступный остаток
        details.push({
          name: i18n.t("main.contracts.details.disposableLimit"),
          textVal: `${this.contract.DisposableLimit} ${this.contract.Currency}`
        });
      }

      // Данные для карточного счёта
      if (this.contract.Data.ContractType == ContractsTypes.CardAccount) {
        if (this.contract.Data.PercentRate > 0) {
          // Процент на остаток
          details.push({
            name: i18n.t("main.contracts.details.percentRest"),
            textVal: `${this.contract.PercentRate}`
          });
        }
        // if (
        //   this.$store.getters.smsPackets &&
        //   this.$store.getters.smsPackets.find(
        //     (item: any) => item.Code === this.contract.Data.SmsPacketId
        //   ) &&
        //   this.contract.Data.SmsPacketId.trim() !== ""
        // ) {
        //   // Пакет SMS информирования
        //   details.push({
        //     name: i18n.t("main.contracts.details.smsPack"),
        //     textVal: `${
        //       this.$store.getters.smsPackets.find(
        //         (item: any) => item.Code === this.contract.Data.SmsPacketId
        //       ).Title
        //     }` //TODO: Заменить на текстовки смс пакетов
        //   });
        // }
        // Нет кредитного лимита
        if (!this.contract.IsUseCreditLimit) {
          // Остаток
          // details.push({
          //   name: i18n.t("main.contracts.details.rest"),
          //   textVal: `${contract.Rest} ${contract.Currency}`,
          // });
        } else {
          // Есть кредитный лимит (Счет кредитной карты или карта с овердрафтом)
          details.push({
            name: i18n.t("main.contracts.details.creditLimit"),
            textVal: `${this.contract.FullLimit} ${this.contract.Currency}`
          });
          //Использованный лимит
          details.push({
            name: i18n.t("main.contracts.details.usedLimit"),
            textVal: `${this.contract.UsedLimit} ${this.contract.Currency}`
          });
          //Собственные средства
          details.push({
            name: i18n.t("main.contracts.details.personalFunds"),
            textVal: `${this.contract.PersonalFunds} ${this.contract.Currency}`
          });
          //Кредитные средства
          details.push({
            name: i18n.t("main.contracts.details.creditFunds"),
            textVal: `${this.contract.CreditFunds} ${this.contract.Currency}`
          });
          if (this.contract.IsViewPercents) {
            //%% по сроку на
            details.push({
              name: i18n.t("main.contracts.details.captionPercOnMainSum", {
                percChargeDate: this.contract.PercChargeDate
              }),
              textVal: `${this.contract.PercOnMainSum} ${this.contract.Currency}`
            });
            //Просроченные %% на
            details.push({
              name: i18n.t("main.contracts.details.captionOutPercOnMainSum", {
                percChargeDate: this.contract.PercChargeDate
              }),
              textVal: `${this.contract.OutPercOnMainSum} ${this.contract.Currency}`
            });
          }

          //Льготный период.
          details.push({
            name: i18n.t("main.contracts.details.gracePayInfo"),
            textVal:
              this.contract.Data.FullLimit <= 0 ||
              this.contract.Data.GraceSum <= 0
                ? "-"
                : this.contract.Data.GraceOutstandingDate != null
                ? i18n.t("main.contracts.details.graceOutstandingText", {
                    graceOutstandingDate: this.contract.GraceOutstandingDate
                  })
                : i18n.t("main.contracts.details.graceEndText", {
                    graceEndDate: this.contract.GraceOutstandingDate
                  })
          });
          //Минимальный платёж
          details.push({
            name: i18n.t("main.contracts.details.minPayInfo"),
            textVal: this.contract.GetMinPayInfo()
          });
        }
      } else if (
        this.contract.Data.ContractType == ContractsTypes.DepositAccounts ||
        this.contract.Data.ContractType == ContractsTypes.TimeAccounts
      ) {
        if (this.contract.Data.IncomeSum > 0) {
          //Полученный доход
          details.push({
            name: i18n.t("main.contracts.details.incomeSum"),
            textVal: `${this.contract.IncomeSum} ${this.contract.Currency}`
          });
        }
        if (this.contract.IsOpen && this.contract.Data.PercentRate > 0) {
          // Процентная ставка
          details.push({
            name: i18n.t("main.contracts.details.percentRate"),
            textVal: `${this.contract.PercentRate}`
          });
        }
      } else if (
        this.contract.Data.ContractType == ContractsTypes.CreditAccount
      ) {
        if (this.contract.IsOpen && this.contract.Data.PercentRate > 0) {
          // Процентная ставка
          details.push({
            name: i18n.t("main.contracts.details.percentRate"),
            textVal: `${this.contract.PercentRate}`
          });
        }
        // Сумма кредита
        details.push({
          name: i18n.t("main.contracts.details.depositSum"),
          textVal: `${this.contract.DepositSum} ${this.contract.Currency}`
        });
        // Срок кредита (месяцев)
        details.push({
          name: i18n.t("main.contracts.details.depositPeriodMonth"),
          textVal: `${this.contract.DepositPeriodMonth}`
        });
        // Досрочное погашение
        details.push({
          name: i18n.t("main.contracts.details.canEarlyRepay"),
          textVal: this.contract.CanEarlyRepay
            ? i18n.t("main.contracts.details.enable")
            : i18n.t("main.contracts.details.disable")
        });
        // Cумма очередного платежа по основному долгу
        details.push({
          name: i18n.t("main.contracts.details.minPaySumMain"),
          textVal: `${this.contract.MinPaySumMain} ${this.contract.Currency}`
        });
        // Cумма очередного платежа по процентам
        details.push({
          name: i18n.t("main.contracts.details.minPaySumPercents"),
          textVal: `${this.contract.MinPaySumPercents} ${this.contract.Currency}`
        });
        // Остаток задолженности на текущую дату по процентам
        details.push({
          name: i18n.t("main.contracts.details.restAcntPercents"),
          textVal: `${this.contract.RestAcntPercents} ${this.contract.Currency}`
        });
      }

      if (this.contract.Data.ContractType == ContractsTypes.TimeAccounts) {
        // Срок (дней)
        details.push({
          name: i18n.t("main.contracts.details.depositPeriod"),
          textVal: `${this.contract.DepositPeriod}`
        });
        // Наличие капитализации
        details.push({
          name: i18n.t("main.contracts.details.isCapitalization"),
          textVal: this.contract.Data.Capitalization
            ? i18n.t("main.contracts.details.yes")
            : i18n.t("main.contracts.details.no")
        });
        // Выплата процентов
        details.push({
          name: i18n.t("main.contracts.details.payPercentsPeriod"),
          textVal: `${this.contract.PayPercentsPeriod}`
        });
      }
    }
    return details;
  }
}