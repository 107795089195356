












import Vue from "vue";
import ContractsPanel from "@/components/contracts-panel/ContractsPanel.vue";
import Slider from "@/components/app/Slider.vue";
import ChoosenPayments from "@/components/catalog/ChoosenPayments.vue";
import CurrencyRates from "@/components/app/CurrencyRates.vue";
import { getModule } from "vuex-module-decorators";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";

/**Страница ифнормации по счету */
export default Vue.extend({
  components: {
    ContractsPanel,
    Slider,
    ChoosenPayments,
    CurrencyRates
  },
  computed: {
    windowWidth(): number {
      const config: ConfigStore = getModule(ConfigStore, this.$store);
      return config.windowWidth;
    }
  }
});
