

































































import { Component } from "vue-property-decorator";
import SumInput from "fs-pb-vue2/components/SumInput.vue";
import { BaseOperationPropertyVue } from "fs-pb-vue2/vueModels/operations/properies/BaseOperationPropertyVue";

@Component({
  components: { SumInput }
})
export default class TextProperty extends BaseOperationPropertyVue {}
