











































import { ForecastModalVue } from "fs-pb-vue2/vueModels/contracts/ForecastModalVue";
import CustomTable from "fs-pb-vue2/components/CustomTable.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { CustomTable }
})
export default class ForecastModal extends ForecastModalVue {}
