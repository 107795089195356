







































































import { MainLayoutVueSNB } from "@/extensions/layouts/MainLayoutVueSNB";
import MainNavigation from "@/components/navigation/MainNavigation.vue";
import CommonFooter from "@/components/navigation/CommonFooter.vue";
import ServerCommandsController from "@/components/app/ServerCommandsController.vue";
import { Component } from "vue-property-decorator";
import AppError from "@/components/app/AppError.vue";
import AuthCheck from "@/components/auth/AuthCheck.vue";

@Component({
  components: { MainNavigation, CommonFooter, AppError, AuthCheck, ServerCommandsController }
})
export default class MainLayout extends MainLayoutVueSNB {
  async created() {
    await this.init();
  }

  beforeDestroy() {
    this.resetStoreStates();
  }
}
