





























































































import { BankSelectModalVue } from "fs-pb-vue2/vueModels/settings/BankSelectModalVue";
import { Component } from "vue-property-decorator";
import VerifyBox from "@/components/app/VerifyBox.vue";

@Component({
  components: { VerifyBox }
})
export default class BankSelectModal extends BankSelectModalVue {
  async mounted() {
    await this.init();
    if (!this.OperationModel.banksList || this.OperationModel.banksList.length === 0)
      this.destroyForm();
  }
}
