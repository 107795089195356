<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
    <v-overlay class="main-preloader" :value="$store.getters['app/getLoading']">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="cookies-policy-wrap" id="cookies-policy" v-if="policyVissible">
      <span
        class="cookies-policy-wrap--text"
        v-html="
          $t('common.cookies-policy', {
            rules: 'https://www.sevnb.ru/about/information/ustav',
          })
        "
      ></span>
      <v-btn @click="closePolicy()">{{ $t("common.close") }}</v-btn>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import "vue-toast-notification/dist/theme-sugar.css";
import "vue-smooth-picker/dist/css/style.css";
import { getCookie, setCookie } from "../node_modules/fs-pb-vue2/utilities/cookieHelper";

/** Главный шаблон приложения */
export default Vue.extend({
  name: "App",
  data: () => ({
    policyVissible: false,
  }),
  created() {
    if (getCookie("policyClosed") !== "true") {
      this.policyVissible = true;
    }
  },
  components: {
    /**Подключаем лэйауты */
    /**Лэйаут для авторизации */
    AuthLayout,
    /**Лэйаут для авторизованных страниц */
    MainLayout,
    /**Лэйаут по умолчанию (если не задан в router) */
    EmptyLayout
  },
  computed: {
    /**Вычисляем имя лэйаута*/
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout"; //TODO:до загрузки лэйаута происходит мерцание и сперва загружается empty
    }
  },
  methods: {
    closePolicy() {
      setCookie("policyClosed", "true");
      this.policyVissible = false;
    }
  },
});
</script>
<style lang="scss">
/**Подключаем шрифты */
@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(./assets/fonts/Raleway-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(./assets/fonts/Raleway-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(./assets/fonts/Raleway-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"),
    url(./assets/fonts/Raleway-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: local("Raleway"), url(./assets/fonts/Raleway-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}

.cookies-policy-wrap {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 1000;
  background: #005696;
  color: #fff;
  opacity: 0.7;
  padding: 6px 20px;

  &--text {
    margin-right: 15px;
    font-size: 14px;
    a {
      color: #fff;
    }
  }
}

@media screen and (max-width: 600px) {
  .cookies-policy-wrap {
    flex-direction: column;
    &--text {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
</style>
