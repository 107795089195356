










































































import { Component } from "vue-property-decorator";
import { BaseOperationPropertyVue } from "fs-pb-vue2/vueModels/operations/properies/BaseOperationPropertyVue";

@Component({})
export default class ListProperty extends BaseOperationPropertyVue {
  accountToClose: string = '';
  operationType: string = '';

  mounted() {
    this.accountToClose = this.$route.query.contract as string;
    this.operationType = this.$route.query.operationType as string;
  }

  get filteredItems() {
    if (this.property.Declaration.Name === 'contractTo' && this.operationType === 'closeAccount') {
      return this.property.Values.filter(item => item.Key !== this.accountToClose);
    }
    return this.property.Values;
  }
}
