




































































































































import Vue from "vue";
import BackLink from "@/components/app/BackLink.vue";
import { ChargesVue } from "fs-pb-vue2/vueModels/charges/ChargesVue";
import i18n from "@/i18n";
import { Component } from "vue-property-decorator";

@Component({
  components: { BackLink }
})
export default class Charges extends ChargesVue {
  async mounted() {
    await this.init();
  }
}
