var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-list-item',{staticClass:"contracts-group",attrs:{"color":"black"}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"contracts-group--header--subtitle",domProps:{"innerHTML":_vm._s(_vm.headerTitle)}})],1),(_vm.filteredDepositItems.length > 0)?_c('v-list-item-action',[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleList(("arrow-" + _vm.headerTitle), _vm.headerTitle)}}},[_c('div',{staticClass:"fs-image-item arrow-grey-icon fs-rotate-180",attrs:{"id":("arrow-" + _vm.headerTitle)}})])],1):_vm._e()],1),(_vm.filteredDepositItems.length > 0)?_c('div',{staticClass:"fs-collapseble",attrs:{"id":_vm.headerTitle,"data-collapsed":"false"}},[_vm._l((_vm.filteredDepositItems),function(contract,i){return [(contract.Data.DateOpen)?_c('div',{key:("contracts-panel-deposits-" + i)},[(i > 0)?_c('v-divider'):_vm._e(),(_vm.isSingleCol)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"link":"","to":("/contract/" + (contract.Data.Id))}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"contracts-group--header--title",domProps:{"textContent":_vm._s(contract.Data.Alias)}}),_c('v-list-item-subtitle',{staticClass:"contracts-group--header--subtitle",domProps:{"innerHTML":_vm._s(((contract.Rest) + " " + (contract.Currency)))}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"contracts-group--item--text",domProps:{"textContent":_vm._s(
                    ((contract.PercentRate) + "  " + (contract.DateEnd != ''
                        ? '      до ' + contract.DateEnd.split('-').join('.')
                        : ''))
                  )}})],1)],1)]}}],null,true)},[_c('span',{domProps:{"innerHTML":_vm._s(((contract.Rest) + " " + (contract.Currency)))}})]):_c('v-list-item',{staticClass:"contracts-group--item",attrs:{"link":"","to":("/contract/" + (contract.Data.Id))}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"contracts-group--header--title",domProps:{"textContent":_vm._s(contract.Data.Alias)}}),_c('v-list-item-subtitle',{staticClass:"contracts-group--header--subtitle",domProps:{"innerHTML":_vm._s(((contract.Rest) + " " + (contract.Currency)))}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"contracts-group--item--text",domProps:{"textContent":_vm._s(
                ((contract.PercentRate) + "  " + (contract.DateEnd != '' ? '      до ' + contract.DateEnd : ''))
              )}})],1)],1)],1):_vm._e()]})],2):_c('div',{staticClass:"pt-3 pl-3"},[_vm._v(_vm._s(_vm.$t("common.emptyList")))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }