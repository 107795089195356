











































import { ScheduleModalVue } from "fs-pb-vue2/vueModels/contracts/ScheduleModalVue";
import CustomTable from "fs-pb-vue2/components/CustomTable.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { CustomTable }
})
export default class ScheduleModal extends ScheduleModalVue {

  get tableKeysSnb(): Array<any> {
    return [
      "Date",
      "PercSum",
      "totalSumPartRem",
      "PenaltySum",
      "TotalSum",
      "MainRest",
      "stateText",
    ];
  }
}
