




























































































































































































































































































































































import { TemplatesVue } from "fs-pb-vue2/vueModels/templates/TemplatesVue";
import { Component } from "vue-property-decorator";
import BackLink from "@/components/app/BackLink.vue";
import FsTemplateDialog from "@/components/templates/FsTemplateDialog.vue";

@Component({
  components: {
    BackLink,
    FsTemplateDialog
  }
})
export default class Templates extends TemplatesVue {

  displayTemplatePeriod(template: TemplateData ): string {
    return `${this.$options.filters.date(template.NextDate, 'datetime')} (${this.getPeriodText(
      template.Period
      )})`;
    }
  }

