import { Prop, Vue, Watch } from "vue-property-decorator";
import { RequestsStates } from "fs-pb-vue2/data/enums/requestsStates";
import { getModule } from "vuex-module-decorators";
import { ContractModel } from "fs-pb-vue2/models/contracts/contractModel";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";
import { OperationModalVue } from "fs-pb-vue2/vueModels/history/OperationModalVue";
import { OperationsHistoryApi } from "fs-pb-vue2/services/api/OperationsHistoryApi";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import { container } from "@/inversify.config";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import i18n from "@/i18n";
 
export class OperationModalVueSNB extends OperationModalVue {
 
  @Watch('details', { immediate: true })
  dialogToggle(val: boolean) {
    if (!val) this.printMenuShow = false;
    if (!val) this.emailOpen = false;
  }
  async detailsComes(val: any) {
    if (val && val.RequestData) {
      await this.init();
    }
  }
 
  get alertService(): IAlertService {
    return container.get<IAlertService>(DiTokens.AlertService);
  }

  /**Стор счетов */
  get configStore(): ConfigStore {
    return getModule(ConfigStore, this.$store);
  }

  get contractAlias(): string {
    return this.contractsStore.contracts.getContractByRefId(this.details.RequestData.contractRef)?.Data?.Alias;
  }
 
  moreDetails: any = [];

  savedDetails: any = [
    {
      isVissible: false,
      value: null,
      key: 'sum',
      withCurrency: true,
      name: i18n.t('main.operations.historyDetails.sum')
    },
    {
      isVissible: false,
      value: null,
      key: 'tariff',
      withCurrency: true,
      name: i18n.t('main.operations.historyDetails.tariff')
    },
    {
      isVissible: false,
      value: null,
      key: 'receiverName',
      name: i18n.t('main.operations.historyDetails.receiverName')
    },
    {
      isVissible: false,
      value: null,
      key: 'phoneNumber',
      name: i18n.t('main.operations.historyDetails.phoneNumber')
    },
    {
      isVissible: false,
      value: null,
      key: 'contractTo',
      name: i18n.t('main.operations.historyDetails.contractTo')
    },
    {
      isVissible: false,
      value: null,
      key: 'card',
      name: i18n.t('main.operations.historyDetails.card')
    },
    {
      isVissible: false,
      value: null,
      key: 'receiverBank',
      name: i18n.t('main.operations.historyDetails.receiverBank')
    },
    {
      isVissible: false,
      value: null,
      key: 'messageToClient',
      name: i18n.t('main.operations.historyDetails.messageToClient')
    },
    {
      isVissible: false,
      isWrapped: true,
      value: null,
      key: 'sbpID',
      name: i18n.t('main.operations.historyDetails.sbpID')
    },
  ];
 
  moreDetailsPreView: string = '';

  printLoading: boolean = false;

  async init(): Promise<void> {
    this.moreDetails = this.savedDetails;
    const catId: string = this.details.RequestData.respondentCode;
    let vissibleKeys: Array<string> = [];

    this.moreDetailsPreView = this.operationData.Sum > 0 ? "Счет зачисления" : "Счет списания";

    if (catId == this.configStore.apiData.OperationCodeOfToOtherContractByPhone || catId == this.configStore.apiData.OperationCodeOfSbpOperation) {
      vissibleKeys = ['phoneNumber', 'receiverBank', 'sbpID', 'messageToClient'];
    } else if (catId == this.configStore.apiData.OperationCodeOfToOtherContractByCard) {
      vissibleKeys = ['receiverBank', 'card'];
    } else if (catId == this.configStore.apiData.OperationCodeOfToContract) {
      vissibleKeys = ['receiverBank', 'contract'];
    } else {
      vissibleKeys = ['receiverName', 'receiverBank', 'phoneNumber', 'card', 'messageToClient'];
    }

    this.moreDetails.forEach((detail: any) => detail.isVissible = vissibleKeys.find((key: string) => key == detail.key) ? true : false);

    if (!catId && catId !== '0') {
      this.moreDetails = this.details.Details;
      this.moreDetails.map((detail: any) => {
        detail.isVissible = true;
        detail.isWrapped = true;
      });
      this.noDetails = true;
    }
    else {
      this.noDetails = false;
      this.loadDetailsValues();
    }
  }

  togglePrintMenu(): void {
    this.printMenuShow = !this.printMenuShow;
    // this.$options.filters.url(`Operations/Print/${this.operationData.Id || this.details.RequestData.operationId}`);
  }

  async goPDF(): Promise<void> {
    this.printLoading = true;
    try {
      const link: string = await OperationsHistoryApi.getOperationPdfAsync(this.operationData.Id || this.details.RequestData.operationId);
      window.open(link, '_blank');
    } catch (e) {
      console.log(e);
    }
    this.printLoading = false;
  }

  toggleEmail(flag: boolean): void {
    this.emailOpen = flag;
    this.printMenuShow = false;
  }

  printMenuShow: boolean = false;

  emailOpen: boolean = false;

  email: string = '';

  emailLoading: boolean = false;

  isEmailValid: boolean = true;

  noDetails: boolean = false;

  get testEmail(): () => void {
    return () => {
      const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      this.isEmailValid = reg.test(this.email);
    };
  }

  async sendEmail(): Promise<void> {
    this.emailLoading = true;
    this.testEmail();
    if (this.isEmailValid) {
      try {
        await OperationsHistoryApi.sendOperationCheckForEmail(this.operationData.Id || this.details.RequestData.operationId, this.email, this.details.RequestData.respondentCode);
        this.alertService.success(i18n.t("main.contracts.messages.successEmail").toString());
        this.toggleEmail(false);
        this.togglePrintMenu();
      } catch (e) {
        console.warn(e);
      }
    }
    this.emailLoading = false;
  }
 
  loadDetailsValues(): void {
    this.moreDetails.forEach((detail: any) => {
      if (detail.key !== 'phoneNumber') {
        const index: number = this.details.RequestData.keys.indexOf(detail.key);
        if (index !== -1) {
          let value: string = this.details.RequestData.values[index];
          if (detail.key == 'card') {
            const values: Array<string> = value.split('|');
            if (values.length === 4)
              value = values[1];
          }
          detail.value = value;
        }
      } else if (detail.key == 'phoneNumber') {
        const index: number = this.details.RequestData.keys.indexOf('contractTo');
        const index2: number = this.details.RequestData.keys.indexOf('phone');
        const index3: number = this.details.RequestData.keys.indexOf('mphone');
        detail.value = index !== -1 && this.details.RequestData.values[index].substring(0,2) == '+7' ? this.details.RequestData.values[index] :
        index2 !== -1 && this.details.RequestData.values[index2] ? this.details.RequestData.values[index2] :
        index3 !== -1 && this.details.RequestData.values[index3] ? this.details.RequestData.values[index3] : null;
      }
    });
 
    if (this.details.RequestData.respondentCode == this.configStore.apiData.OperationCodeOfSbpOperation) {
      const index: number = this.details.RequestData.keys.indexOf('type');
      if (index !== -1 && this.details.RequestData.values[index] == 'SBP') {
        this.moreDetails.find((detail: any) => detail.key == 'sum').isVissible = true;
        this.moreDetails.find((detail: any) => detail.key == 'tariff').isVissible = true;
      }
    }
  }

  stringRequestState(state: RequestsStates): string {
    let stringState = "";
    switch (state) {
      case RequestsStates.Executed:
        stringState = i18n.t("main.operation.stateExecuted").toString();
        break;
      case RequestsStates.Executing:
        stringState = i18n.t("main.operation.stateExecuting").toString();
        break;
      case RequestsStates.ExecuteError:
      case RequestsStates.Denied:
        stringState = i18n.t("main.operation.stateDenied").toString();
        break;
      default:
        stringState = i18n.t("main.operation.stateDenied").toString();
        break;
    }
    return stringState;
  }
}