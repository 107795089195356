






















































































































































































































































































import { OperationModalVueSNB } from "@/extensions/history/OperationModalVueSNB";
import { Component } from "vue-property-decorator";
import FsTemplateDialog from "@/components/templates/FsTemplateDialog.vue";

@Component({
  components: { FsTemplateDialog }
})
export default class OperationModal extends OperationModalVueSNB {}
