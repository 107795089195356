var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.name !== 'Home')?_c('v-navigation-drawer',{staticClass:"left-sidebar pl-6",staticStyle:{"width":"300px"},attrs:{"app":"","left":""},model:{value:(_vm.isLeftSideOpen),callback:function ($$v) {_vm.isLeftSideOpen=$$v},expression:"isLeftSideOpen"}},[_c('contracts-panel',{attrs:{"isSingleCol":""},on:{"hideSideBar":_vm.leftSideHide}})],1):_vm._e(),_c('v-app-bar',{staticClass:"header",attrs:{"app":"","elevation":"1","color":"white","clipped-left":""}},[_c('div',{staticClass:"bank-logo"},[_c('a',{on:{"click":function($event){return _vm.pushMain()}}},[_c('div',{staticClass:"bank-logo--image fs-image-item"})]),(!_vm.isLeftSideOpen && _vm.$route.name !== 'Home')?_c('div',{staticClass:"left-side-opener",on:{"click":_vm.hideShowSidebar}},[_vm._v(" Показать счета и карты ")]):_vm._e()]),_c('v-spacer'),_c('v-toolbar',{staticClass:"nav-menu pt-1",attrs:{"flat":""}},_vm._l((_vm.links),function(link,index){return _c('router-link',{key:("main-header-toolbar-" + index),staticClass:"nav-menu--item mr-13",attrs:{"tag":"div","active-class":"active","to":link.url,"exact":""}},[_c('a',[_vm._v(_vm._s(link.title))])])}),1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"notify-link",attrs:{"icon":""},on:{"click":_vm.toNotifications}},'v-btn',attrs,false),on),[(_vm.newNotifiesCount > 0)?_c('v-badge',{attrs:{"color":"red","content":_vm.newNotifiesCount,"bordered":""}},[_c('div',{staticClass:"fs-image-item notify-icon"})]):_c('div',{staticClass:"fs-image-item notify-icon"})],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("main.header.links.notifications")))])]),_c('div'),_c('v-avatar',{staticClass:"ml-6",attrs:{"size":"35"}},[(_vm.personInfo && _vm.personInfo.Data.Avatar)?_c('img',{attrs:{"src":("data:image/gif;base64," + (_vm.personInfo.Data.Avatar))}}):_c('img',{attrs:{"src":require("./../../../public/img/default-avatar.png")}})]),_c('div',{staticClass:"person-greeting ml-3"},[_c('p',{staticClass:"greeting-text"},[_vm._v(_vm._s(_vm.greeting)+",")]),(_vm.personInfo)?_c('p',{staticClass:"person-name"},[_vm._v(" "+_vm._s(_vm.personInfo.Data.Name)+" ")]):_vm._e()]),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dropdown-btn",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('div',{staticClass:"fs-image-item dropdown-icon"})])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/templates","link":"","exact":""}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.header.links.templates")))])],1),_c('v-list-item',{attrs:{"to":"/profile","link":"","exact":""}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.header.links.profile")))])],1)],1)],1),(_vm.windowWidth >= 1016)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"button-out",attrs:{"icon":""},on:{"click":function($event){return _vm.logout()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"logout-icon fs-image-item"})],1)]}}],null,false,3240367835)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.header.links.logOut")))])]):_vm._e(),_c('v-app-bar-nav-icon',{staticClass:"mobile-menu-toggler",on:{"click":function($event){return _vm.hideShowMenu()}}})],1),(_vm.windowWidthCalc <= 1016)?_c('v-navigation-drawer',{staticClass:"mobile-menu",attrs:{"app":"","right":""},model:{value:(_vm.isMenuOpen),callback:function ($$v) {_vm.isMenuOpen=$$v},expression:"isMenuOpen"}},[_c('v-list',[_vm._l((_vm.links),function(link,index){return _c('v-list-item',{key:("main-header-right-sidebar-" + index),attrs:{"link":"","to":link.url,"exact":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(link.title))])],1)],1)}),_c('v-list-item',{attrs:{"link":"","to":"/notifications","exact":""}},[(_vm.newNotifiesCount > 0)?_c('v-badge',{attrs:{"color":"red","content":_vm.newNotifiesCount,"inline":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.header.links.notifications")))])],1)],1):_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.header.links.notifications")))])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/templates","exact":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.header.links.templates")))])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/profile","exact":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.header.links.profile")))])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("main.header.links.logOut")))])],1)],1)],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }