







































































































































































import { Component } from "vue-property-decorator";
import OperationProperties from "@/components/operations/OperationProperties.vue";
import BackLink from "@/components/app/BackLink.vue";
import DetailsList from "@/components/app/DetailsList.vue";
import VerifyBox from "@/components/app/VerifyBox.vue";
import { CatalogOperationVueFS } from "@/extensions/operations/CatalogOperationVueFS";
import AccountInDefaultModal from "@/components/settings/AccountInDefaultModal.vue";
import SbpEnableModal from "@/components/settings/SbpEnableModal.vue";
import i18n from "@/i18n";

@Component({
  components: { OperationProperties, DetailsList, VerifyBox, BackLink, SbpEnableModal, AccountInDefaultModal }
})
export default class CatalogOperation extends CatalogOperationVueFS {
  mounted() {
    if (this.isSBPOperToMySelf && this.personStore.personInfo.Data.DefaultInAccount.length === 0) {
      this.alertService.sbp(i18n.t('main.settings.errors.sbpInAcntinclude'), true);
      this.accntInModalShow = true;
    }    
  }
}
