







































































































































































































































import { OperationsListVue } from "fs-pb-vue2/vueModels/history/OperationsListVue";
import OperationBlock from "@/components/history/OperationBlock.vue";
import OperationModal from "@/components/history/OperationModal.vue";
import OperationsFilter from "@/components/history/OperationsFilter.vue";
import DatePicker from "fs-pb-vue2/components/datePicker/DatePicker.vue";
import MyChart from "fs-pb-vue2/components/MyChart.vue";
import SearchInput from "fs-pb-vue2/components/SearchInput.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    OperationBlock,
    DatePicker,
    SearchInput,
    OperationModal,
    OperationsFilter,
    MyChart
  }
})
export default class OperationsList extends OperationsListVue {
  async mounted() {
    await this.init();
    this.model.isPeriod = true;
  }
}
