






































































































































































































































import { BaseContractVueSNB } from "@/extensions/contract/BaseContractVueSNB";
import ContractDetails from "@/components/contracts/ContractDetails.vue";
import { Component } from "vue-property-decorator";
import Requisites from "@/components/contracts/Requisites.vue";
import BackLink from "@/components/app/BackLink.vue";
import OperationsList from "@/components/history/OperationsList.vue";
import ContractSmsModal from "@/components/contractOperations/ContractSmsModal.vue";
import ForecastModal from "@/components/contracts/ForecastModal.vue";
// import Vue from "vue";
import ScheduleModal from "@/components/contracts/ScheduleModal.vue";
// import { AppStore } from "fs-pb-vue2/store/modules/app";
// import { ConfigStore } from "fs-pb-vue2/store/modules/config";
// import { PersonStore } from "fs-pb-vue2/store/modules/person";
// import { ContractsStore } from "fs-pb-vue2/store/modules/contracts";
// import { getModule } from "vuex-module-decorators";
// import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    Requisites,
    ContractDetails,
    BackLink,
    OperationsList,
    ContractSmsModal,
    ForecastModal,
    ScheduleModal
  }
})
export default class BaseContract extends BaseContractVueSNB {}
