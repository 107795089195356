var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',_vm._l((2),function(n){return _c('v-skeleton-loader',{key:("profile-left-load-" + n),staticClass:"mb-5",attrs:{"height":"48","max-width":"551","type":"image"}})}),1):_c('div',{staticClass:"d-block settings--main-actions documents"},[_vm._l((_vm.subscriptions),function(sub,index){return _c('div',{key:("subs-" + index),staticClass:"\n        fs-control-item-multiline fs-control-item-multiline--card-type\n        mb-3\n      ",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.editItem(sub)}}},[_c('div',{staticClass:"fs-control-item-multiline--card-type--info",on:{"click":function($event){return _vm.editItem(sub)}}},[_c('h3',{staticClass:"fs-control-item-multiline--card-type--info--title mb-1"},[_vm._v(" "+_vm._s(sub.Document.Name)+" ")]),_c('p',{staticClass:"fs-control-item-multiline--card-type--info--text mb-1"},[_vm._v(" "+_vm._s(sub.Document.Type.Category)+" ")]),_c('p',{staticClass:"fs-control-item-multiline--card-type--info--text"},[_vm._v(" "+_vm._s(((sub.Document.Type.DisplayName) + ": " + (sub.Document.Number)))+" ")])]),_c('div',{staticClass:"fs-control-item-multiline--card-type--right"},[_c('div',{staticClass:"fs-control-item-multiline--card-type--right--close"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteSub(sub)}}},'v-btn',attrs,false),on),[_c('img',_vm._g(_vm._b({attrs:{"height":"14px","src":require("./../../../public/img/close.png")}},'img',attrs,false),on))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.templates.delete")))])])],1),_c('div',{staticClass:"fs-control-item-multiline--card-type--right--signs"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!sub.Active)?_c('img',_vm._g(_vm._b({attrs:{"src":require("./../../../public/img/notification_disable.png")}},'img',attrs,false),on)):(_vm.isVip)?_c('img',_vm._g(_vm._b({attrs:{"src":require("./../../../public/img/notification_vip.png")}},'img',attrs,false),on)):_c('img',_vm._g(_vm._b({attrs:{"src":require("./../../../public/img/notification.png")}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(("Информирование " + (sub.Active ? "подключено" : "отключено"))))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!sub.AutoExecute)?_c('img',_vm._g(_vm._b({attrs:{"src":require("./../../../public/img/active_disable.png")}},'img',attrs,false),on)):(_vm.isVip)?_c('img',_vm._g(_vm._b({attrs:{"src":require("./../../../public/img/active_vip.png")}},'img',attrs,false),on)):_c('img',_vm._g(_vm._b({attrs:{"src":require("./../../../public/img/active.png")}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(("Автоплатеж " + (sub.AutoExecute ? "подключен" : "отключен"))))])])],1)])])}),_c('v-btn',{staticClass:"fs-main-btn mr-13",on:{"click":function($event){return _vm.addItem()}}},[_vm._v("Создать новый шаблон: информирование/автоплатеж")]),_c('span',{staticClass:"docs-desc my-5"},[_vm._v("Возможность настроить информирование из систем ГИС ЖКХ и ГИС ГМП и создать автоплатеж оплаты коммунальных услуг, налогов и штрафов.")])],2),_c('v-dialog',{attrs:{"scrollable":false,"max-width":"460px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('subscription-edit',{attrs:{"item":_vm.selectedItem,"isNew":_vm.isNew},on:{"cencel":function($event){return _vm.closeDialog()}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }