import { DeclarationPropertiesTypes } from "fs-pb-vue2//data/enums/declarationPropertiesTypes";
import { OperationRequestData } from "fs-pb-vue2//data/operationRequestData";
import i18n from "@/i18n";
import { RecoveryModel } from "fs-pb-vue2/models/auth/RecoveryModel";

/** Модель регистрации меткома */
export class SnbRecoveryModel extends RecoveryModel {

  /**Правила заполнения номера карты/счета */
  public AccountNumberRules: Array<any> = [
    (l: any) => !!l || i18n.t("auth.errors.accountNumberCheck")
  ];

  constructor() {
    super();
  }

  /**
   * Создание запроса операции.
   */
  public createOperationRequest(): OperationRequestData {
    const ord = new OperationRequestData();
    ord.respondentCode = this.RespondentCode;
    ord.operationId = this.OperationId;

    ord.setProperty("account", this.AccountNumber.replace(/\s|\(|\)|\-/g, ""), DeclarationPropertiesTypes.String);
    ord.setProperty("phone", this.Phone.replace(/\s|\(|\)|\-/g, ""), DeclarationPropertiesTypes.String);
    ord.setProperty("number", this.AccountNumber.replace(/\s/g, ""), DeclarationPropertiesTypes.String);

    return ord;
  }

  /**
* Заполнение операции данными из ответа запроса.
* @param ord Данные запроса.
* @param bAssign Признак assign.
*/
  public setOperationFromRequest(ord: OperationRequestData): void {
    this.Login = ord.getPropertyValue("dboLogin");
    this.SuccessMessage = i18n.t("auth.messages.successrecoverylogin", { login: this.Login }).toString();
  }
}