import { DeclarationPropertiesTypes } from "fs-pb-vue2//data/enums/declarationPropertiesTypes";
import { OperationRequestData } from "fs-pb-vue2//data/operationRequestData";
import i18n from "@/i18n";
import { RegisterModel } from "fs-pb-vue2/models/auth/RegisterModel";

/** Модель регистрации пользователя Snb  */
export class SnbRegisterModel extends RegisterModel {
    /**Правила заполнения номера карты */
    public AccountNumberRules: Array<any> = [
      (l: any) => !!l || i18n.t("auth.errors.panEmpty")
    ];
  
    constructor() {
      super();
    }
  
    /**
     * Создание запроса операции.
     */
    public createOperationRequest(): OperationRequestData {
      const ord = new OperationRequestData();
      ord.respondentCode = this.RespondentCode;
      ord.operationId = this.OperationId;
  
      ord.setProperty("account", this.AccountNumber.replace(/\s|\(|\)|\-/g, ""), DeclarationPropertiesTypes.String);
      ord.setProperty("phone", this.Phone.replace(/\s|\(|\)|\-/g, ""), DeclarationPropertiesTypes.String);
      ord.setProperty("number", this.AccountNumber.replace(/\s/g, ""), DeclarationPropertiesTypes.String);
  
      return ord;
    }
}