var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("" + (_vm.isSingleCol ? 'single-col' : ''))},[_c('div',{class:("contracts-panel pr-5 " + (_vm.isSingleCol ? 'py-5' : 'py-8'))},[(_vm.loading)?_c('v-skeleton-loader',{class:("" + (_vm.isSingleCol ? 'mb-4' : 'mb-7')),attrs:{"width":"450","type":"heading"}}):_c('h1',{class:("block-header  " + (_vm.isSingleCol ? 'mb-4' : 'mb-7'))},[_vm._v(" "+_vm._s(_vm.$t("main.contracts.contracts"))+" "),(_vm.$route.name !== 'Home')?_c('span',{staticClass:"side-bar-closer",on:{"click":function($event){return _vm.hideSideBar()}}},[_vm._v("×")]):_vm._e(),(_vm.openDepositOperation)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.toDepositOpening()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1)]}}],null,false,658557640)},[_c('span',[_vm._v(_vm._s(_vm.$t("main.contracts.openСontracts")))])]):_vm._e()],1),(_vm.contracts.isEmpty && !_vm.loading)?_c('span',{class:("" + (_vm.isSingleCol ? 'mb-4' : 'mb-7'))},[_vm._v(" "+_vm._s(_vm.$t("common.emptyList"))+" ")]):_vm._e(),(_vm.loading)?_c('v-row',{class:("" + (_vm.isSingleCol ? 'mb-4' : 'mb-7'))},_vm._l((6),function(n){return _c('v-col',{key:("contracts-panel-contracts-load-" + n),class:_vm.isSingleCol ? '' : _vm.windowWidth <= 1150 ? '' : 'mr-md-15',style:(_vm.isSingleCol ? '' : 'min-width: 324px;'),attrs:{"justify":"space-between","cols":"12","sm":_vm.isSingleCol ? 12 : 6,"xs":"12","md":_vm.isSingleCol ? 12 : 3}},[_c('v-skeleton-loader',{attrs:{"height":"65","type":"image"}}),(n < 3)?_c('v-skeleton-loader',{attrs:{"height":"50","type":"list-item"}}):_vm._e(),(n % 2 == 0)?_c('v-divider'):_vm._e(),(n % 2 == 0)?_c('v-skeleton-loader',{attrs:{"height":"50","type":"list-item"}}):_vm._e()],1)}),1):(_vm.contracts)?_c('v-row',{class:("" + (_vm.isSingleCol ? 'mb-4' : 'mb-7'))},_vm._l((_vm.contracts.Contracts),function(contract,i){return _c('ContractBlock',{directives:[{name:"show",rawName:"v-show",value:(
          (contract.IsOpen && !contract.Data.Hidden) || _vm.isHiddenItemsVissible
        ),expression:"\n          (contract.IsOpen && !contract.Data.Hidden) || isHiddenItemsVissible\n        "}],key:("contracts-panel-contracts-" + i),attrs:{"contract":contract,"isSingleCol":_vm.isSingleCol}})}),1):_c('div',{staticClass:"empty-contracts fs-info"},[_vm._v(" "+_vm._s(_vm.$t("main.contracts.emptyContracts"))+" ")]),_c('v-row',{class:("" + (_vm.isSingleCol ? 'mb-4' : 'mb-7'))},[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.contracts && _vm.contracts.Deposits.length),expression:"contracts && contracts.Deposits.length"}],class:_vm.isSingleCol ? '' : _vm.windowWidth <= 1150 ? '' : 'mr-md-15',style:(_vm.isSingleCol ? '' : 'min-width: 324px;'),attrs:{"justify":"space-between","cols":"12","sm":_vm.isSingleCol ? 12 : 6,"xs":"12","md":_vm.isSingleCol ? 12 : 3}},[(_vm.loading)?_c('v-skeleton-loader',{class:("" + (_vm.isSingleCol ? 'mb-4' : 'mb-7')),attrs:{"type":"heading"}}):(
            _vm.contracts && (_vm.contracts.Deposits.length || _vm.openDepositOperation)
          )?_c('div',{class:("d-flex align-center  " + (_vm.isSingleCol ? 'mb-4' : 'mb-7'))}):_vm._e(),(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"height":"65","type":"image"}}),_c('v-skeleton-loader',{attrs:{"height":"50","type":"list-item"}}),_c('v-divider'),_c('v-skeleton-loader',{attrs:{"height":"50","type":"list-item"}}),_c('v-divider'),_c('v-skeleton-loader',{attrs:{"height":"50","type":"list-item"}})],1):(_vm.contracts && _vm.contracts.Deposits.length)?_c('div',[_c('DepositBlock',{attrs:{"isSingleCol":_vm.isSingleCol,"depositItems":_vm.contracts.Deposits,"headerTitle":_vm.$t('main.contracts.deposits')}})],1):_vm._e()],1),_c('v-col',{class:_vm.isSingleCol ? '' : _vm.windowWidth <= 1150 ? '' : 'mr-md-15',style:(_vm.isSingleCol ? '' : 'min-width: 324px;'),attrs:{"justify":"space-between","cols":"12","sm":_vm.isSingleCol ? 12 : 6,"xs":"12","md":_vm.isSingleCol ? 12 : 3}},[(_vm.loading)?_c('v-skeleton-loader',{class:("" + (_vm.isSingleCol ? 'mb-4' : 'mb-7')),attrs:{"type":"heading"}}):_vm._e(),_c('div',{class:("d-flex align-center  " + (_vm.isSingleCol ? 'mb-4' : 'mb-7')),staticStyle:{"visibility":"hidden"}}),(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"height":"65","type":"image"}}),_c('v-skeleton-loader',{attrs:{"height":"50","type":"list-item"}}),_c('v-divider'),_c('v-skeleton-loader',{attrs:{"height":"50","type":"list-item"}}),_c('v-divider'),_c('v-skeleton-loader',{attrs:{"height":"50","type":"list-item"}})],1):(_vm.contracts && _vm.contracts.Credits.length)?_c('div',[_c('DepositBlock',{attrs:{"isSingleCol":_vm.isSingleCol,"depositItems":_vm.contracts.Credits,"headerTitle":_vm.$t('main.contracts.credits')}})],1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }