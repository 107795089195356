import { SnbRegisterModel } from "@/extensions/models/auth/SnbRegisterModel";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import AnonymousOperationService from "fs-pb-vue2/services/operations/AnonymousOperationService";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";
import storeAccessor from "fs-pb-vue2/store/storeAccessor";
import { getModule } from "vuex-module-decorators";
import { AuthStore } from "fs-pb-vue2/store/modules/auth";
import IErrorService from "fs-pb-vue2/services/error/IErrorService";
import { inject, injectable } from "inversify";
import i18n from "@/i18n";

/**Сервис проведения операций */
@injectable()
export default class SnbRegisterService extends AnonymousOperationService {
  constructor(
    @inject(DiTokens.AlertService) public alertService: IAlertService,
    @inject(DiTokens.ErrorService) public errorService: IErrorService
  ) {
    super(alertService, errorService);
  }

  /**
 * Инициализация операции
 * @param initData Данные для инициализации
 * */
    public async init(): Promise<SnbRegisterModel> {
    const operationData = new SnbRegisterModel();
    operationData.RespondentCode =
      this.configStore.apiData.OperationCodeOfRegistration || "RegistrationPerson";
    return operationData;
  }

  public async nextStep(model: SnbRegisterModel): Promise<SnbRegisterModel> {
    return (await this.execute(model)) as SnbRegisterModel;
  }

  public async execute(actionModel: SnbRegisterModel): Promise<SnbRegisterModel> {
    try {
      actionModel = await super.execute(actionModel) as SnbRegisterModel;
    } catch (e) {
      actionModel.ActionStateText = e.message;
      if (e.message.includes('По указанным данным клиент в системе банка не найден')) {
        this.alertService.error(i18n.t('auth.messages.errorRegistered').toString(), false);
      } else
        await this.errorService.errorHandler(e, false, false, "");
    }
    return actionModel;
  }

  public async afterExecute(model: SnbRegisterModel): Promise<any> {
    debugger
    this.authStore.setLogin(model.Login);
  }

  /**Стор настроек */
  get configStore(): ConfigStore {
    return getModule(ConfigStore, storeAccessor.store);
  }

  /**Стор настроек */
  get authStore(): AuthStore {
    return getModule(AuthStore, storeAccessor.store);
  }
}