


























































































































































































import i18n from "@/i18n";
import Vue from "vue";
import VerifyBox from "@/components/app/VerifyBox.vue";
import { SubscriptionEditVue } from "fs-pb-vue2/vueModels/subscription/SubscriptionEditVue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    VerifyBox
  }
})
export default class SubscriptionEdit extends SubscriptionEditVue {
  mounted() {
    if (this.$refs.subsForm) this.$refs.subsForm.resetValidation();
    // this.fillData(this.item);
  }
}

