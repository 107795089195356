









































import { Component } from "vue-property-decorator";
import { OperationPropertiesVue } from "fs-pb-vue2/vueModels/operations/OperationPropertiesVue";
import TextProperty from "@/components/operations/propeties/TextProperty.vue";
import ListProperty from "@/components/operations/propeties/ListProperty.vue";
import SwitchProperty from "@/components/operations/propeties/SwitchProperty.vue";
import ButtonProperty from "@/components/operations/propeties/ButtonProperty.vue";
import DateTimeProperty from "@/components/operations/propeties/DateTimeProperty.vue";
import UserAccountProperty from "@/components/operations/propeties/UserAccountProperty.vue";

@Component({
  components: {
    TextProperty,
    UserAccountProperty,
    DateTimeProperty,
    ListProperty,
    ButtonProperty,
    SwitchProperty
  }
})
export default class OperationProperties extends OperationPropertiesVue {}
