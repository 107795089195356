import { SnbRecoveryModel } from "@/extensions/models/auth/SnbRecoveryModel";
import IAlertService from "fs-pb-vue2/services/alert/IAlertService";
import AnonymousOperationService from "fs-pb-vue2/services/operations/AnonymousOperationService";
import { DiTokens } from "fs-pb-vue2/services/diTokens";
import { ConfigStore } from "fs-pb-vue2/store/modules/config";
import storeAccessor from "fs-pb-vue2/store/storeAccessor";
import { getModule } from "vuex-module-decorators";
import { AuthStore } from "fs-pb-vue2/store/modules/auth";
import IErrorService from "fs-pb-vue2/services/error/IErrorService";
import { inject, injectable } from "inversify";
import i18n from "@/i18n";

/**Сервис восстановления доступа снб*/
@injectable()
export default class SnbRecoveryService extends AnonymousOperationService {
  constructor(
    @inject(DiTokens.AlertService) public alertService: IAlertService,
    @inject(DiTokens.ErrorService) public errorService: IErrorService
  ) {
    super(alertService, errorService);
  }

  /**
 * Инициализация операции
 * @param initData Данные для инициализации
 * */
  public async init(): Promise<SnbRecoveryModel> {
    const operationData = new SnbRecoveryModel();
    operationData.RespondentCode =
      this.configStore.apiData.OperationCodeOfRecoveryLogin || "RecoveryLogin"
    return operationData;
  }

  public async nextStep(model: SnbRecoveryModel): Promise<SnbRecoveryModel> {
    return (await super.nextStep(model)) as SnbRecoveryModel;
  }

  public async prepare(actionModel: SnbRecoveryModel): Promise<SnbRecoveryModel> {
    try {
      actionModel = await super.prepare(actionModel) as SnbRecoveryModel;
    } catch (e) {
      actionModel.ActionStateText = e.message;
      if (e.message.includes('Клиент по указанным данным не найден!')) {
            this.alertService.error(i18n.t('auth.messages.errorRecover').toString(), false);
          } else
            await this.errorService.errorHandler(e, false, false, "");
    }
    return actionModel;
  }

  public async afterExecute(model: SnbRecoveryModel): Promise<any> {
    this.authStore.setLogin(model.Login);
  }
  
    /**Стор настроек */
  get configStore(): ConfigStore {
    return getModule(ConfigStore, storeAccessor.store);
  }

   /**Стор настроек */
   get authStore(): AuthStore {
    return getModule(AuthStore, storeAccessor.store);
  }
}