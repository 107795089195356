









































































































































import { LoginVue } from "fs-pb-vue2/vueModels/auth/LoginVue";
import { Component } from "vue-property-decorator";
import BackLink from "@/components/app/BackLink.vue";

@Component({
  components: { BackLink }
})
export default class Login extends LoginVue {
    
  async mounted() {
    await this.init();
  }

  validateForm(): void {
    // Проверяем форму на валидность
    (this.$refs.form as any).validate();
  }

  resetFormValidation(): void {
    // Сбрасываем реззультаты валидации
    (this.$refs.form as any).resetValidation();
  }
}
