
























































































































































import { TemplatesVue } from "fs-pb-vue2/vueModels/templates/TemplatesVue";
import CatalogIcon from "@/components/catalog/CatalogIcon.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: { CatalogIcon }
})
export default class ChoosenPayments extends TemplatesVue {}
