













































































































































import BackLink from "@/components/app/BackLink.vue";
import { CatalogVue } from "fs-pb-vue2/vueModels/catalog/CatalogVue";
import CatalogIcon from "@/components/catalog/CatalogIcon.vue";
import SearchInput from "fs-pb-vue2/components/SearchInput.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: { CatalogIcon, SearchInput, BackLink }
})
export default class Catalog extends CatalogVue {
  async mounted(): Promise<void> {
    await this.getCharges();
  }
}
