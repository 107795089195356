import { render, staticRenderFns } from "./CurrencyRates.vue?vue&type=template&id=e05ee6dc&scoped=true&"
import script from "./CurrencyRates.vue?vue&type=script&lang=ts&"
export * from "./CurrencyRates.vue?vue&type=script&lang=ts&"
import style0 from "./CurrencyRates.vue?vue&type=style&index=0&id=e05ee6dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e05ee6dc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCol,VList,VListItem,VRow,VSkeletonLoader})
