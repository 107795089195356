














































































































































































































import { ProfileVue } from "fs-pb-vue2/vueModels/profile/ProfileVue";
import SubscriptionList from "@/components/subscription/SubscriptionList.vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    SubscriptionList
  }
})
export default class Profile extends ProfileVue {}
