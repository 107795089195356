








































































import { getRandInt } from "fs-pb-vue2/utilities/index";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class DetailsList extends Vue {
  /**Признак загрузки */
  @Prop() loading!: boolean;
  // /**Минимальная длина строк прилодера */
  // @Prop() preloaderMin!: number;
  // /**Максимальная длина строк прилодера */
  // @Prop() preloaderMax!: number;
  /**Детали 
   * {
      disabled: true,
      name: '',
      textVal: '',
      isAction: true,
      model: object,
      action: f()
    }
  */
  @Prop() details!: Array<any>;

  async callAction(action: () => Promise<void>) {
    await action();
  }

  getRandWidth() {
    return getRandInt(30, 100);
  }
}
