




























































































































































































import { Component } from "vue-property-decorator";
import { CardVue } from "fs-pb-vue2/vueModels/contracts/CardVue";
import ContractDetails from "@/components/contracts/ContractDetails.vue";
import CardBlockModal from "@/components/cardOperations/CardBlockModal.vue";
import CardPinModal from "@/components/cardOperations/CardPinModal.vue";

@Component({
  components: {
    ContractDetails,
    CardBlockModal,
    CardPinModal
  }
})
export default class Card extends CardVue {}
