









































import { VerifyBoxVue } from "fs-pb-vue2/vueModels/app/VerifyBoxVue";
import { Component } from "vue-property-decorator";

@Component({})
export default class VerifyBox extends VerifyBoxVue {
  /** При создании присваеваем внешнее значение кода внутрненему */
  created() {
    this.modelValue = this.value;
    this.getDefaultTimerValue();
  }
  /** После создания запускаем таймеры и отображаем таймер на дисплей */
  mounted() {
    this.initTimers();
    this.getTimerValue();
  }
  /** Перед закрытыием опустощаем значение кода, чистим все таймеры */
  beforeDestroy() {
    this.modelValue = "";
    this.clearTimers();
    clearTimeout(this.veriCodetimer);
  }
}
