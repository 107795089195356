











































































































































import { SettingsVueSNB } from "@/extensions/settings/SettingsVueSNB";
import AliasEditModal from "@/components/settings/AliasEditModal.vue";
import PasswordEditModal from "@/components/settings/PasswordEditModal.vue";
import SbpEnableModal from "@/components/settings/SbpEnableModal.vue";
import DefaultOutAccount from "@/components/settings/DefaultOutAccount.vue";
import AccountInDefaultModal from "@/components/settings/AccountInDefaultModal.vue";
import BankSelectModal from "@/components/settings/BankSelectModal.vue";
import SbpDefaultModal from "@/components/settings/SbpDefaultModal.vue";
import { Component } from "vue-property-decorator";
import ActionsList from "@/components/app/ActionsList.vue";

@Component({
  components: {
    AliasEditModal,
    PasswordEditModal,
    SbpEnableModal,
    SbpDefaultModal,
    ActionsList,
    DefaultOutAccount,
    AccountInDefaultModal,
    BankSelectModal
  }
})
export default class Settings extends SettingsVueSNB {}
