































































































































































import { RecoveryVue } from "fs-pb-vue2/vueModels/auth/RecoveryVue";
import { Component } from "vue-property-decorator";
import VerifyBox from "@/components/app/VerifyBox.vue";
import BackLink from "@/components/app/BackLink.vue";
import { RecoveryModel } from "fs-pb-vue2/models/auth/RecoveryModel";

@Component({
  components: { VerifyBox, BackLink }
})
export default class Recovery extends RecoveryVue {
  async mounted() {
    await this.init();
  }

  loginDialog: boolean = false;
  loginDialogContent: string = "";
  closeLoginDialog(): void {
    this.loginDialog = false;
    this.$router.push(
      `/login`
    );
  }

  /**Метод для выполнения после успешного проведения */
  async afterExecute(): Promise<void> {
    this.loginDialogContent = (this.OperationModel as RecoveryModel).SuccessMessage;
    this.loginDialog = true;    
  }
}
