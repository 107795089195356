































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DetailsList from "@/components/app/DetailsList.vue";
import ActionsList from "@/components/app/ActionsList.vue";
import OperationsList from "@/components/history/OperationsList.vue";
import { StatementModel } from "fs-pb-vue2/models/statement/statementModel";

@Component({
  components: { DetailsList, ActionsList, OperationsList }
})
/**Нижний блок информации по счету */
export default class ContractDetails extends Vue {
  /**Признак загрузки */
  @Prop() loading!: boolean;
  /**Детали 
   * {
      disabled: true,
      name: '',
      textVal: '',
      isAction: true,
      model: object,
      action: f()
    }
  */
  @Prop() details!: Array<any>;

  /**Действия 
   * {
      text: '',
      iconClass: '',
      action: f()
    }
  */
  @Prop() actions!: Array<any>;
  @Prop() detailsTitle!: Array<any>;

  @Prop() statementModel!: StatementModel;

  @Watch("$route", { immediate: true })
  changed(to, from) {
    this.$forceUpdate();
    this.detailsActiveHref = "about";
  }

  // getLoading: boolean = true
  //   return this.loading;
  // }
  /**Активный таб*/
  detailsActiveHref: string = "about";

  get windowWidth(): number {
    return window.innerWidth;
  }
}
