





































































import { SliderVue } from "fs-pb-vue2/vueModels/app/SliderVue";
import { Component } from "vue-property-decorator";

@Component({})
export default class Slider extends SliderVue {
  created() {
    window.addEventListener("resize", this.updateWidth);
  }
  mounted() {
    this.updateWidth();
  }
}
