






import Vue from "vue";
import CatalogOperation from "@/components/operations/CatalogOperation.vue";

/**Страница ифнормации по счету */
export default Vue.extend({
  components: {
    CatalogOperation
  },
  computed: {
    operId(): string {
      return this.$route.params.id;
    },
    operInitType(): string {
      return this.$route.params.type;
    }
  }
});
