


























































































































































import { RegisterVue } from "fs-pb-vue2/vueModels/auth/RegisterVue";
import { Component } from "vue-property-decorator";

@Component({
})
export default class Register extends RegisterVue {
  
  enabled: boolean = false;

  async mounted() {
    await this.init();
  }
}
